<template>
  <UiNewsListItemDesktop
    v-bind="props"
    class="hidden lg:flex"
    data-test="ui-news-list-item-desktop"
    :class="$attrs.class"
  >
    <template
      v-for="(_, name) in $slots"
      #[name]="slotData"
    >
      <slot
        :name="name"
        v-bind="slotData"
      />
    </template>
  </UiNewsListItemDesktop>

  <UiNewsListItemMobile
    v-bind="props"
    class="lg:hidden"
    data-test="ui-news-list-item-mobile"
    :class="$attrs.class"
  >
    <template
      v-for="(_, name) in $slots"
      #[name]="slotData"
    >
      <slot
        :name="name"
        v-bind="slotData"
      />
    </template>
  </UiNewsListItemMobile>
</template>

<script setup lang="ts">
import UiNewsListItemDesktop from './UiNewsListItemDesktop/UiNewsListItemDesktop.vue'
import UiNewsListItemMobile from './UiNewsListItemMobile/UiNewsListItemMobile.vue'
import type { UiNewsItem } from './UiNewsListItem.types'

const props = withDefaults(defineProps<UiNewsItem>(), {
  teaser: '',
  datetime: '',
  datetimeShort: '',
  tags: () => [],
  categories: () => [],
  readTime: undefined,
  moreLink: '',
  variant: '',
  addMoreInfo: true,
  labels: null,
  colorOfGradient: '',
  sizeVariant: 'comfort',
})
</script>
